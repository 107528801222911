<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[语音转写]听语音写文字，20/时，在家可做</p>
    <div>
      <div class="title">【招募岗位】</div>
      <p class="psd">语音转写</p>
    </div>
    <div>
      <div class="title">【工作内容】</div>
      <p class="psd">将听到的语音内容，转写成文字</p>
    </div>
    <div>
      <div class="title">【工作要求】</div>
      <p class="psd">时间自由，地点不限，有网就能做</p>
    </div>
    <div>
      <div class="title">【福利待遇】</div>
      <p class="psd">20/小时</p>
    </div>
    <div>
      <div class="title">【报名方式】</div>
      <img src="@/assets/images/xywork.jpg" alt="" class="pic" />
    </div>
    <div>
      <div class="title">【躺平无忧提示您】</div>
      <p class="msg">
        <span>1、涉及到交纳会费、看淘宝淘气值、花呗等相关的内容都是诈骗。</span>
        <span>2、未标明有费用的岗位，涉及到收费的都可联系客服反馈。</span>
        <span>3、请大家仔细辨别相关招聘内容，谨防诈骗</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
}
</style>
